<template>
  <div
    class="FloorPlanIssueMapper"
    @click="addMarker"
  >
    <BImgLazy
      fluid
      :src="`https://${cdnUrl}/media/fill/1140x1140/255,255,255/${image}`"
    />
    <div
      v-for="(marker, key) in markers"
      :key="key"
      class="FloorPlanIssueMapper__markerContainer"
    >
      <div
        class="FloorPlanIssueMapper__marker"
        :style="marker.style"
      >
        {{ key + 1 }}
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../shared/config/eventBus'
import md5 from '../../shared/utils/md5'

export default {
  props: {
    image: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    markers: [],
  }),

  computed: {
    cdnUrl () {
      return this.$store.state.site.cdnUrl
    },
  },

  mounted () {
    EventBus.$on('issueReportDeleted', issueId => {
      this.deleteMarker(issueId)
    })

    EventBus.$on('allIssueReportsDeleted', () => {
      this.deleteAllMarkers()
    })
  },

  methods: {
    addMarker (event) {
      if (this.markers.length > 9) {
        return
      }

      const rect = event.currentTarget.getBoundingClientRect()

      const absolutePos = {
        x: Math.floor(event.clientX - rect.left - 12),
        y: Math.floor(event.clientY - rect.top - 6),
      }

      const percentPos = {
        x: Math.floor((absolutePos.x / rect.width) * 100),
        y: Math.floor((absolutePos.y / rect.height) * 100),
      }

      const marker = {
        id: md5(`x:${absolutePos.x},y:${absolutePos.y},time:${new Date().getTime()}`),
        style: {
          left: `${percentPos.x}%`,
          top: `${percentPos.y}%`,
        },
        position: percentPos,
      }

      this.markers.push(marker)

      EventBus.$emit('floorPlanMarkerAdded', {
        markers: this.markers,
        currentMarker: marker,
      })
    },

    deleteMarker (markerId) {
      const key = this.markers.findIndex(marker => {
        return marker.id === markerId
      })

      if (key >= 0) {
        this.$delete(this.markers, key)
        EventBus.$emit('floorPlanMarkerDeleted', markerId)
      }
    },

    deleteAllMarkers () {
      this.markers = []
      EventBus.$emit('allFloorPlanMarkersDeleted')
    },
  },
}
</script>
