import { Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Import store modules here
import * as site from './site'

export default new Store({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    site,
  },

  plugins: [createPersistedState({
    paths: [
      'site',
    ],
  })],
})
