import Vue from 'vue'

import store from '../config/vuex/store'

const documentsPageMountElement = document.querySelector('.DocumentsPage')

if (documentsPageMountElement) {
  new Vue({
    name: 'DocumentsPageRoot',

    store,
  }).$mount(documentsPageMountElement)
}
