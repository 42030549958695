<template>
  <BRow>
    <BCol md="6">
      <div v-if="hasFloorPlan">
        <p>
          Marker området for feilen/mangelen på plantegningen. Beskriv for oss hva skaden gjelder, last opp bilder. Din
          henvendelse vil bli videreformidlet til rette vedkommende.
        </p>
        <FloorPlanIssueMapper
          :image="apartment.floorPlan.path"
        />
      </div>
      <div v-else>
        <p>
          Beskriv for oss hva skaden gjelder, last opp bilder. Din henvendelse vil bli videreformidlet til rette
          vedkommende.
        </p>

        <BButton
          class="mt-3"
          variant="primary"
          @click="addIssue"
        >
          <FontAwesomeIcon :icon="['fas', 'plus']" />
          Legg til feil/mangel
        </BButton>
      </div>
    </BCol>
    <BCol md="6">
      <BAlert
        variant="success"
        :show="submitSuccessful"
      >
        Din henvendelse ble sendt.
      </BAlert>

      <BForm @submit.prevent="submitForm">
        <div
          v-for="(issue, key) in issues"
          :key="`issue-${key}`"
        >
          <BCard
            class="ReportIssueCard"
            no-body
          >
            <BCardHeader class="ReportIssueCard__header">
              <h2 class="ReportIssueCard__title">
                #{{ key + 1 }}
              </h2>

              <BButton
                variant="outline-danger"
                size="sm"
                @click="deleteIssue(issue.id)"
              >
                <span class="sr-only">Fjern feil/mangel</span>
                <FontAwesomeIcon :icon="['fas', 'trash']" />
              </BButton>
            </BCardHeader>
            <BCardBody>
              <BFormGroup
                :id="`input-group-select-room-${issue.id}`"
                label="Velg rom"
                :label-for="`input-select-room-${issue.id}`"
              >
                <BFormSelect
                  :id="`input-select-room-${issue.id}`"
                  v-model="issues[key].form.room"
                  :options="roomOptions"
                  value-field="id"
                  text-field="name"
                  required
                  :disabled="isSubmitting"
                />
              </BFormGroup>

              <BFormGroup
                :id="`input-group-images-${issue.id}`"
                label="Velg bilder"
                :label-for="`input-images-${issue.id}`"
              >
                <BFormFile
                  :id="`input-images-${issue.id}`"
                  v-model="issues[key].form.images"
                  accept="image/jpeg, image/png"
                  placeholder="Ingen bilder valgt"
                  browse-text="Bla gjennom"
                  drop-placeholder="Slipp filer her"
                  :file-name-formatter="formatFileNames"
                  multiple
                  :disabled="isSubmitting"
                />
              </BFormGroup>

              <BFormGroup
                :id="`input-group-description-${issue.id}`"
                label="Beskrivelse"
                :label-for="`input-description-${issue.id}`"
                class="mb-0"
              >
                <BFormTextarea
                  :id="`input-description-${issue.id}`"
                  v-model="issues[key].form.description"
                  required
                  placeholder="Beskriv kort skaden"
                  :disabled="isSubmitting"
                />
              </BFormGroup>
            </BCardBody>
          </BCard>
        </div>

        <template v-if="issues.length">
          <BButton
            v-if="isSubmitting"
            variant="primary"
            block
            size="lg"
            disabled
          >
            <BSpinner
              variant="light"
              small
            />
            Sender…
          </BButton>

          <BButton
            v-else
            variant="primary"
            type="submit"
            block
            size="lg"
          >
            Send inn
          </BButton>
        </template>
      </BForm>
    </BCol>
  </BRow>
</template>

<script>
import Axios from 'axios'
import EventBus from '../../shared/config/eventBus'
import FloorPlanIssueMapper from '@/mypage/components/FloorPlanIssueMapper'
import md5 from '../../shared/utils/md5'
import reverseString from '@/shared/utils/reverseString'

export default {
  components: {
    FloorPlanIssueMapper,
  },

  props: {
    apartment: {
      type: Object,
      required: true,
    },
    rooms: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    issues: [],
    isSubmitting: false,
    submitSuccessful: false,
  }),

  computed: {
    hasFloorPlan () {
      return (this.apartment.floorPlan && this.apartment.floorPlan.path)
    },

    roomOptions () {
      return [
        {
          id: 1349,
          name: 'Rom ikke valgt',
        },
        ...this.rooms,
      ]
    },
  },

  mounted () {
    EventBus.$on('floorPlanMarkerAdded', this.addIssueFromEvent)
  },

  methods: {
    addIssueFromEvent (event) {
      this.addIssue({
        id: event.currentMarker.id,
        x: event.currentMarker.position.x,
        y: event.currentMarker.position.y,
      })
    },

    addIssue (data) {
      if (typeof data.x === 'undefined') {
        data.x = 0
      }

      if (typeof data.y === 'undefined') {
        data.y = 0
      }

      if (typeof data.id === 'undefined') {
        data.id = md5(`x:${data.x},y:${data.y},time:${new Date().getTime()}`)
      }

      this.issues.push({
        id: data.id,
        form: {
          room: 1349,
          images: [],
          description: '',
        },
        x: data.x,
        y: data.y,
      })
    },

    deleteIssue (issueId) {
      const key = this.issues.findIndex(issue => {
        return issue.id === issueId
      })

      if (key >= 0) {
        this.$delete(this.issues, key)
        EventBus.$emit('issueReportDeleted', issueId)
      }
    },

    deleteAllIssues () {
      this.issues = []
      EventBus.$emit('allIssueReportsDeleted')
    },

    submitForm () {
      this.isSubmitting = true

      const formData = new FormData()

      formData.append('issues', JSON.stringify(this.issues.map(issue => {
        return {
          id: issue.id,
          room: issue.form.room,
          description: issue.form.description,
          x: issue.x,
          y: issue.y,
        }
      })))

      const addedFileNames = []

      this.issues.forEach(issue => {
        if (issue.form.images.length > 0) {
          issue.form.images.forEach((image, index) => {
            let imageName = image.name

            // Rewrite image names if necessary to avoid collisions
            if (addedFileNames.includes(imageName)) {
              const [, matchedExtension, matchedNumber, matchedFileName] = /^([^ .]+\.)?(?:\)(\d+)\( ?)?(.*)/.exec(reverseString(image.name))

              let number = matchedNumber ? Number(reverseString(matchedNumber)) : 0
              const extension = reverseString(matchedExtension)
              const fileName = reverseString(matchedFileName)

              while (addedFileNames.includes(imageName)) {
                imageName = `${fileName} (${++number})${extension}`
              }
            }

            formData.append(`files[${issue.id}][${index}]`, image, imageName)

            addedFileNames.push(imageName)
          })
        }
      })

      Axios.post(`/api/issues/apartment/${this.apartment.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        this.isSubmitting = false
        this.submitSuccessful = true
        this.deleteAllIssues()
      }).catch(error => {
        this.isSubmitting = false

        this.$bvToast.toast('Din henvendelse ble ikke sendt.', {
          variant: 'danger',
          title: 'Noe gikk galt',
        })

        console.error(error)
      })
    },

    formatFileNames (files) {
      if (files.length === 1) {
        return files[0].name
      } else {
        return `${files.length} filer valgt`
      }
    },
  },
}
</script>
