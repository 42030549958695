import Vue from 'vue'

import store from '../config/vuex/store'

const faqPageMountElement = document.querySelector('.FAQPage')

if (faqPageMountElement) {
  new Vue({
    name: 'FAQPageRoot',

    store,
  }).$mount(faqPageMountElement)
}
