import Vue from 'vue'

import store from '../config/vuex/store'

const accountPageMountElement = document.querySelector('.AccountPage')

if (accountPageMountElement) {
  new Vue({
    name: 'AccountPageRoot',

    store,
  }).$mount(accountPageMountElement)
}
