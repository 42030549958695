import Vue from 'vue'

import store from '../config/vuex/store'

import ReportIssues from '@/mypage/components/ReportIssues'

Vue.component('report-issues', ReportIssues)

const reportPageMountElement = document.querySelector('.ReportPage')

if (reportPageMountElement) {
  new Vue({
    name: 'ReportPageRoot',

    store,
  }).$mount(reportPageMountElement)
}
