import Vue from 'vue'

import store from '../config/vuex/store'

import DetailsList from '@/shared/components/DetailsList'
import DetailsListItem from '@/shared/components/DetailsListItem'
import FloorPlan from '@/shared/components/FloorPlan'
import IssueMessageCard from '@/shared/components/IssueMessageCard'

Vue.component('details-list', DetailsList)
Vue.component('details-list-item', DetailsListItem)
Vue.component('floor-plan', FloorPlan)
Vue.component('issue-message-card', IssueMessageCard)

const issuePageMountElement = document.querySelector('.IssuePage')

if (issuePageMountElement) {
  new Vue({
    name: 'IssuePageRoot',

    store,
  }).$mount(issuePageMountElement)
}
