import Vue from 'vue'

import store from '../config/vuex/store'

const issuesPageMountElement = document.querySelector('.IssuesPage')

if (issuesPageMountElement) {
  new Vue({
    name: 'IssuesPageRoot',

    store,
  }).$mount(issuesPageMountElement)
}
