import Vue from 'vue'

import { library, dom } from '@apility/fontawesome-svg-core'
import { FontAwesomeIcon } from '@apility/vue-fontawesome'

import {
  faArrowRight as fasArrowRight,
  faCaretDown as fasCaretDown,
  faDoorOpen as fasDoorOpen,
  faDownload as fasDownload,
  faEnvelope as fasEnvelope,
  faGarageCar as fasGarageCar,
  faHouse as fasHouse,
  faInboxIn as fasInboxIn,
  faInboxOut as fasInboxOut,
  faKey as fasKey,
  faPaperPlane as fasPaperPlane,
  faPhone as fasPhone,
  faPlus as fasPlus,
  faRulerCombined as fasRulerCombined,
  faSave as fasSave,
  faTachometer as fasTachometer,
  faTimesSquare as fasTimesSquare,
  faTrash as fasTrash,
  faUser as fasUser,
  faUserCircle as fasUserCircle,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faArrowRight as farArrowRight,
} from '@fortawesome/pro-regular-svg-icons'

Vue.component(FontAwesomeIcon.name, FontAwesomeIcon)

library.add(
  farArrowRight,
  fasArrowRight,
  fasCaretDown,
  fasDoorOpen,
  fasDownload,
  fasEnvelope,
  fasGarageCar,
  fasHouse,
  fasInboxIn,
  fasInboxOut,
  fasKey,
  fasPaperPlane,
  fasPhone,
  fasPlus,
  fasRulerCombined,
  fasSave,
  fasTachometer,
  fasTimesSquare,
  fasTrash,
  fasUser,
  fasUserCircle,
)

dom.watch()
