import Vue from 'vue'

import store from '../config/vuex/store'

const mastheadMountElement = document.querySelector('.Masthead')

if (mastheadMountElement) {
  new Vue({
    name: 'MastheadRoot',

    store,
  }).$mount(mastheadMountElement)
}
