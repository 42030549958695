import Vue from 'vue'

import store from '../config/vuex/store'

import DetailsList from '@/shared/components/DetailsList'
import DetailsListItem from '@/shared/components/DetailsListItem'
import FloorPlan from '@/shared/components/FloorPlan'

Vue.component('details-list', DetailsList)
Vue.component('details-list-item', DetailsListItem)
Vue.component('floor-plan', FloorPlan)

const myApartmentPageMountElement = document.querySelector('.MyApartmentPage')

if (myApartmentPageMountElement) {
  new Vue({
    name: 'MyApartmentPageRoot',

    store,
  }).$mount(myApartmentPageMountElement)
}
